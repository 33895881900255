.profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
}

.profile-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 2vw;
  padding: 4vw;
  font-size: 1.5vw;
  grid-template-columns: 1fr;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
}

.profile-label {
  font-size: 2.5vw;
}

.profile-table-column-center {
  text-align: center;
}

.profile-table-bold {
  font-weight: 500;
}

.profile-table-text-red {
  color: red !important;
}

.profile-table-checkbox {
  border-color: red !important;
}

.form-check-input {
  border: 1px solid cornflowerblue !important;
}

.store-id-component {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
}

.store-id-display-component {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 0.3vw;
}

.store-id-display-store-span {
  margin-left: 0.3vw;
  margin-right: 0.3vw;
  font-weight: 500;
}

.store-id-display-store-empty-component {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 0.3vw;
}

.store-id-display-store-empty-span {
  color: red;
  opacity: 50%;
}

.store-id-clear-button {
  font-size: 1.5vw;
  margin-left: auto;
}

.store-id-input-component {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: row;
  margin-bottom: 0.3vw;
}

.store-id-input {
  font-size: 1.5vw;
  width: 100%;
}

.store-id-button {
  align-self: flex-end;
  appearance: none;
  outline: none;
  border: none;
  background-color: orange;
  box-shadow: 0.1vw 0.1vw 0.5vw dimgray;
  border-radius: 0.5vw;
  padding: 0.5vw 2vw 0.5vw 2vw;
  color: black;
  font-weight: 700;
  font-size: 1vw;
  transition: all 0.3s;
}

@media (hover: hover) {
  .store-id-button:hover,
  .store-id-button:focus {
    box-shadow: 0.1vw 0.1vw 0.8vw orange;
    cursor: pointer;
  }
}

.store-id-button:active {
  box-shadow: 0.1vw 0.1vw 1vw orange;
  background-color: orange;
}

.store-id-button:disabled {
  box-shadow: 0.1vw 0.1vw 1vw darkorange;
  background-color: darkorange;
  cursor: auto;
  opacity: 50%;
}

.store-id-spinner {
  margin-left: 0.5vw;
  width: 1vw !important;
  height: 1vw !important;
  border: 0.2vw solid currentColor !important;
  border-right-color: transparent !important;
}

.sign-out-component {
  margin-top: 1vw;
  align-self: flex-end;
}

.sign-out-button {
  appearance: none;
  outline: none;
  border: none;
  background-color: orange;
  box-shadow: 0.1vw 0.1vw 0.5vw dimgray;
  border-radius: 0.5vw;
  padding: 0.5vw 2vw 0.5vw 2vw;
  color: black;
  font-weight: 700;
  font-size: 1.5vw;
  transition: all 0.3s;
}

@media (hover: hover) {
  .sign-out-button:hover,
  .sign-out-button:focus {
    box-shadow: 0.1vw 0.1vw 0.8vw orange;
    cursor: pointer;
  }
}

.sign-out-button:active {
  box-shadow: 0.1vw 0.1vw 1vw orange;
  background-color: orange;
}

.sign-out-button:disabled {
  box-shadow: 0.1vw 0.1vw 1vw darkorange;
  background-color: darkorange;
  cursor: auto;
}

.sign-out-spinner {
  margin-left: 0.5vw;
  width: 1.5vw !important;
  height: 1.5vw !important;
  border: 0.2vw solid currentColor !important;
  border-right-color: transparent !important;
}

.profile-event-dropdown .Dropdown-control {
  padding: 0.1vw 0vw 0.1vw 0.8vw;
  font-size: 1.5vw;
}

.profile-event-dropdown .Dropdown-menu {
  padding: 0.1vw 0vw 0.1vw 0.8vw;
  font-size: 1.2vw;
}
