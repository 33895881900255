.prepared-shipment-event-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
  width: 100%;
  font-size: 0.9vw;
  border: 0.1vw solid cornflowerblue;
}

.prepared-shipment-event-pane {
  margin: 0.2vw;
  width: 100%;
  height: auto;
}

.prepared-shipment-event-vertical-segment {
  display: flex;
  flex-direction: column;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  /* padding-top: 0.2vw; */
}

.prepared-shipment-event-card-segment-list {
  display: flex;
  flex-direction: column;
  padding-left: 0.2vw;
  padding-right: 0.2vw;
  padding-top: 0.1vw;
}

.prepared-shipment-event-card-segment {
  display: flex;
  flex-direction: column;
  border-radius: 0.5vw;
  margin-bottom: 0.1vw;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  border: 0.1vw solid cornflowerblue;
}

.prepared-shipment-event-card-id-label {
  font-weight: 700;
  font-size: 0.8vw;
}

.prepared-shipment-event-card-id-value {
  font-size: 0.7vw;
}

.prepared-shipment-event-horizontal-segment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  white-space: nowrap;
}

.prepared-shipment-event-horizontal-delimiter {
  border-top: 0.1vw solid gray;
  /* margin-top: 0.2vw; */
}

.prepared-shipment-event-vertical-delimiter {
  border-right: 0.1vw solid gray;
  margin-left: 0.2vw;
  margin-right: 0.2vw;
}

.prepared-shipment-event-attribute-label {
  font-weight: 700;
  font-size: 1.4vw;
}

.prepared-shipment-event-id-label {
  font-size: 1.4vw;
}

.prepared-shipment-event-date-label {
  font-size: 1vw;
}

.prepared-shipment-event-card-id-label {
  font-weight: 700;
  font-size: 1.3vw;
}

.prepared-shipment-event-card-id-value {
  font-size: 1.3vw;
}

.prepared-shipment-event-attribute-value {
  padding-left: 0.2vw;
}

.prepared-shipment-event-padding-bottom {
  padding-bottom: 0.2vw;
}
