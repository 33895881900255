.event-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  max-height: 76vh;
}

.event-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2vw;
  padding-top: 1vw;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 3vw;
  font-size: 3.5vw;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
  height: 100%;
}

.event-copyright {
  margin-bottom: 1vw;
  font-size: 1.7vw;
}

.event-barcode-container {
  display: flex;
  flex-direction: row;
}

.event-barcode {
  margin-top: 2vw;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
}

.event-label {
  font-weight: 700;
  text-align: center;
}

.event-label-email {
  font-weight: 500;
  color: cornflowerblue;
  margin-left: 0.5vw;
}
