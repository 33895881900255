.processed-order-event-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.25), 0 1vw 1vw rgba(0, 0, 0, 0.22);
  border-radius: 0.5vw;
  width: 100%;
  font-size: 0.9vw;
  border: 0.1vw solid cornflowerblue;
}

.processed-order-event-pane {
  margin: 0.2vw;
  width: 100%;
  height: auto;
}

.processed-order-event-vertical-segment {
  display: flex;
  flex-direction: column;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  /* padding-top: 0.2vw; */
}

.processed-order-event-horizontal-segment {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  white-space: nowrap;
}

.processed-order-event-horizontal-delimiter {
  border-top: 0.1vw solid cornflowerblue;
  /* margin-top: 0.2vw; */
}

.processed-order-event-vertical-delimiter {
  border-right: 0.1vw solid cornflowerblue;
  margin-left: 0.2vw;
  margin-right: 0.2vw;
}

.processed-order-event-attribute-label {
  font-weight: 700;
  font-size: 1.4vw;
}

.processed-order-event-id-label {
  font-size: 1.4vw;
}

.processed-order-event-date-label {
  font-size: 1vw;
}

.processed-order-event-attribute-value {
  padding-left: 0.2vw;
}

.processed-order-event-bottom-attribute-label {
  font-weight: 700;
  font-size: 1vw;
}

.processed-order-event-bottom-attribute-value {
  font-size: 1vw;
}

.processed-order-event-padding-bottom {
  padding-bottom: 0.2vw;
}
