.navigation-bar {
  height: 5vw;
}

.navigation-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 2vw;
}

.navigation-section {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  font-size: 2vw;
}

.navigation-right-section {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
}

.navbar-brand {
  white-space: nowrap;
  margin-left: 1vw;
  margin-right: 1vw !important;
  padding-top: 0vw !important;
  padding-bottom: 0vw !important;
}

.navbar-brand-container {
  display: flex;
  height: 100%;
}

.navbar-brand-text-label {
  font-size: 2.5vw;
  cursor: pointer;
  white-space: nowrap;
  color: orange;
  font-family: bd-geminis, sans-serif;
  font-weight: 400;
  font-style: normal;
  height: 100%;
}

.nav-normal-text-label {
  padding: 0px;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
  height: 100%;
}

.nav-vertical-delimiter {
  border-left: 0.1vw solid gray;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.nav-item-generic {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 4vw;
  /* padding-left: 0.5vw;
  padding-right: 0.5vw; */
}

.nav-item-login {
  color: lightgray;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item-login-container {
  color: lightgray;
  display: flex;
  align-items: center;
}

.nav-register-label {
  font-size: 1.5vw;
  padding: 0px;
  color: lightgray;
  white-space: nowrap;
}

.nav-register-link {
  font-size: 1.5vw;
  padding-left: 0.3vw;
  cursor: pointer;
  font-weight: bold;
  color: gray;
  white-space: nowrap;
  text-decoration: none;
}
/* 
@media screen and (max-width: 992px) {
  .navigation-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .navigation-section {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
  }

  .navigation-right-section {
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    width: 100%;
  }

  .navbar-brand {
    white-space: nowrap;
    padding-left: 2vw;
  }

  .navbar-brand-text-label {
    font-size: 1.2vw;
    cursor: pointer;
    white-space: nowrap;
    color: orange;
  }

  .nav-vertical-delimiter {
    border-top: 0.1vw solid gray;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
  }

  .nav-item-generic {
    margin: 0.2vw;
  }

  .nav-item-login {
    margin: 0.2vw;
  }

  .nav-normal-text-label {
    font-size: 1.5vw;
    padding: 0px;
    cursor: pointer;
    font-weight: bold;
  }

  .nav-item-login-container {
    color: lightgray;
    display: flex;
  }

  .nav-register-label {
    font-size: 1vw;
    padding: 0vw;
    padding-right: 0.2vw;
    color: lightgray;
    white-space: nowrap;
    width: 50%;
  }

  .nav-register-link {
    font-size: 1vw;
    padding: 0vw;
    padding-left: 0.2vw;
    cursor: pointer;
    font-weight: bold;
    color: gray;
  }
} */
